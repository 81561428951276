import React, { Component } from "react"
import Footer from "../../components/base/Footer"
import Header from "../../components/base/Header"
import SEO from "../../components/base/seo"

const termsandcondition = {
  title1: `Arus Oil - 服务条款`,
  paragraph1: `本文件阐述了My Protech Sdn Bhd（1307580-A）（"My Protech"）的条款和条件（"条款"），其注册地址为 IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia（"我们"，"我们 "或 "我们的"）为您（"您"，"您 "或 "用户"）访问 "Arus Oil"（"App"）提供服务。请仔细阅读这些条款。`,
  paragraph2: `通过网站创建账户或以其他方式使用本应用，您表明您接受这些服务条款。如果你不接受这些服务条款，请不要使用本本应用或其任何内容或服务。`,
  paragraph3: `这些服务条款可由My Protech随时修订或更新，恕不另行通知，而且自您上次访问本网站以来，服务条款可能已经改变。您有责任查看这些服务条款的任何变化。您在这些服务条款的任何修订或更新后的使用，应标志着您同意并接受这些修订后的条款。除非另有说明，本网站可能不时增加的任何新功能将受这些服务条款的约束。请定期访问本页面，审查这些服务条款。`,
  paragraph4: `为了使用这些服务，您必须向我们注册并提供某些信息。关于我们如何使用您的个人信息的信息，请参见Arus Oil的隐私政策，其条款已通过引用纳入本协议。如果您不同意我们的隐私政策，请不要使用我们的应用程序或服务。`,
  paragraph5: `Arus Oil 本应用是一个平台，"卖家 "可以将他们的废食油（"UCO"）出售给 "买家"。销售价格将根据全球棕榈油价格波动，并将在结账前告知所有卖家。卖家在接受报价之前必须同意这些条款和条件。一旦提出报价，买方将被提示此类报价，并允许安排物流提取UCO，付款将直接进入卖方的银行账户。`,
  definition: `定义`,
  paragraph6:
    "“应用程序” 是指Arus Oil应用程序的目标代码形式，可在网络浏览器上使用，并可通过苹果、Windows、Google Play和亚马逊应用程序商店下载。Arus Oil应用程序提供一个在线空间，使用户能够 ",
  users: `("卖家")`,
  paragraph7: `出售废食油 `,
  usedcookingoil: `("UCO")`,
  paragraph8: `指派取货、公布UCO价格和促进付款的用户是 `,
  buyer: `"买家"`,
  paragraph9: `和安排领取UCO的用户是 `,
  sellers: `"卖家"`,
  paragraph10: `买方监控所有的预约，并通过内部人员或第三方促进提货，并通过第三方支付服务促进付款直接到卖方的银行账户。售出的UCO和在应用程序中进行的所有交易都是不可退还的。您必须注册一个账户以访问和使用本应用程序的功能，并保持您的账户信息准确。`,
  title2: `关于Arus Oil的应用信息`,
  point1: `该应用程序由My Protech拥有和管理。所有通过该服务提供的信息都由My Protech管理。`,
  point2: `My Protech可以通过写信给其注册办公室，地址是 IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia。 `,
  point3: `该应用程序在谷歌Firebase上托管和管理，并遵守谷歌服务的所有条款和条件以及隐私政策。`,
  title3: `服务的使用`,
  paragraph11: `你必须至少年满18岁才能以用户身份注册本应用程序。注册时，您必须提供您的全名、电子邮件地址和其他所需信息。用户向我们提供的任何个人信息将按照Arus Oil的隐私政策进行存储，您应仔细阅读该政策。我们要求您在创建账户时提供您的真实姓名。对于因您提交任何虚假、不正确或不完整的信息，或未能按需要更新您的注册信息而导致我们或任何其他人产生的任何及所有损失、损害和额外费用，您将承担全部责任和义务。以下是用户作出的与注册和维护其帐户安全有关的承诺。 `,
  sellerresp: `卖家的责任`,
  point4: `在注册时提供准确的信息并保持这些信息的更新。`,
  point5: `保持您的用户登录信息和对本应用的访问安全。对于因您的账户或密码被泄露而造成的任何损失，包括任何未经授权进入您的账户或使用，我们将不承担任何责任。如果您怀疑或发现任何未经授权使用您的电子邮件登录或密码或任何其他违反安全的行为，请立即联系我们。`,
  point6: `尊重与您互动的本应用的其他用户，不参与任何威胁、骚扰或其他对其他用户或第三方有害的行为，或做出任何违反以下所列的一般用户行为准则的行为。如果您违反这些要求或以下部分的限制，我们保留终止您对本应用的访问并采取适当法律行动的权利;`,
  point7: `同意不上传任何有害的、滥用的、构成仇恨言论的、骚扰性的、歧视性的或其他令人反感的以及对其他用户和/或公众有害的内容或创建任何用户组或事件;`,
  point8: `如果我们禁用一个用户的账户，未经我们允许，他们不会再创建另一个账户。`,
  point9: `如果您接受这些条款并代表公司、组织、政府或其他法律实体使用服务，您声明并保证您已被授权这样做`,
  point10: `除本条款明确允许的情况外，您不得，也不得允许他人: `,
  point11: `修改、翻译、创建衍生副本或复制全部或部分应用程序;`,
  point12: `逆向工程、反编译、反汇编或以其他方式将本应用的目标代码还原为源代码形式。;`,
  point13: `分發、再授權、轉讓、分享、分時使用、出售、出租、授予擔保權益、用於服務局目的，或以其他方式轉讓本应用或您使用本应用的權利。;`,
  point14: `移除或修改本应用內屬於我們或我們的授權人的任何版權、商標或其他所有權聲明; 或者`,
  point15: `以本條款未明確授權的任何方式使用本应用。`,
  myprotechresp: `My Protech的职责 `,
  point16: `我们努力提供最好的服务，但您理解并同意，本网站上或通过本网站提供的特点、功能、信息和材料是 "按原样 "提供的。具体而言，但不限于此，Arus Oil不表示、保证或提供任何条件，即:`,
  point17: `本网站上的信息或材料是正确、准确、可靠或完整的;`,
  point18: `本网站所包含的功能将是不间断的或没有错误的;`,
  point19: `缺陷将被纠正; 或者`,
  point20: `本网站或使其可用的技术不含病毒或其他有害成分。My Protech明确表示不承担任何明示、暗示、法定、贸易惯例、交易过程或其他方面的陈述、保证和条件，包括但不限于任何关于适销性、非侵权、所有权、令人满意的质量或适用于特定目的的暗示保证。通过使用本网站下载或以其他方式获得的任何信息或材料，均由您自行决定并承担风险，您将对下载或使用任何此类材料导致的计算机系统损坏、数据丢失或任何其他损失承担全部责任。My Protech不保证、认可、担保、提供任何条件或陈述，也不对任何第三方通过网站宣传或提供的任何产品或服务承担任何责任，也不对可通过网站上的链接或在网站上的任何横幅或其他广告中出现的任何网站承担任何责任，并且时间计数不应成为您与任何此类第三方达成的任何交易的一方。`,
  point21: `在任何情况下，My Protech都不对任何间接的、偶然的、特殊的、后果性的、惩戒性的或其他的损害负责，包括但不限于以下原因造成的任何间接的、偶然的、特殊的、后果性的、惩戒性的或其他的损害: `,
  point22: `您使用或无法使用本网站或本应用中的任何信息或材料;`,
  point23: `采购替代货物、数据、信息或服务的费用; `,
  point24: `网站上的材料有错误、误差或不准确之处; `,
  point25: `因您使用本应用而引起或与之相关的任何形式的人身伤害、死亡或财产损失; `,
  point26: `任何虫子、病毒、特洛伊木马或任何其他可能对计算机或通信设备有害的文件或数据，或可能被传送到或通过网站传送的数据; 或者`,
  point27: `网站上任何材料的任何错误或遗漏，或因您使用本应用程序而引起的或与之相关的任何其他损失或损害。即使My Protech已被告知此类损害的可能性，这些限制也应适用。前述限制应在法律允许的最大范围内适用。`,
  point28: `我們保留不時對本应用或其部分作出變更的權利，包括但不限於移除、修改及/或變更本应用的任何元素、特色及功能。`,
  point29: `在不影響我們的其他權利和補救措施的情況下，我們保留權利在任何時間暫時或永久暫停或終止您對本应用的存取，而無須通知您，如果您違反本文的任何規定。`,
  point30: `若我們自行決定認為您正在非法和/或未經授權使用本应用，和/或您對本应用的使用違反本條款，我們保留採取我們認為必要的任何行動的權利，包括不經通知終止您對本应用的使用，以及在非法使用的情況下，提出法律訴訟。 `,
  title4: `会员`,
  point31: `您的应用程序会员资格是免费的，并将以月为周期持续到终止。 `,
  point32: `我们可能会提供由第三方提供的促销活动，同时提供他们自己的产品和服务。我们不对会员计划的产品和服务数量负责，包括特别促销计划或由此类第三方提供的会员资格。`,
  title5: `应用程序的使用`,
  point33: `当您访问本应用时，您承认并同意:`,
  point34: `My Protech与苹果、亚马逊、谷歌商店、Windows或安卓没有任何联系、关联或附属关系。; `,
  point35: `对本应用的访问和使用可能受到您的网络运营商的限制，如果您试图从您的网络运营商的区域之外访问本应用，则会受到限制。; `,
  point36: `您承认，除非您通过GPRS、3G或Wi-Fi连接的移动设备接入互联网，否则您将无法访问和使用本应用的某些功能。由于使用本应用而产生的所有流量费或接入费均受您与您的移动网络提供商商定的条款约束; `,
  point37: `您的通话时间提供商或任何应用程序商店或售货亭或售货亭运营商（包括但不限于谷歌商店、苹果商店、亚马逊和Windows Phone Marketplace）均不承担任何与应用程序的销售、分销、功能、可及性、支持或性能或不性能有关的责任或义务; `,
  point38: `您的通话时间提供者和任何应用程序亭或亭运营商（包括但不限于谷歌商店、苹果商店、亚马逊和Windows Phone Marketplace）是本条款的第三方受益人。; `,
  point39: `My Protech完全负责提供与本应用有关的任何支持和维护；并且您在使用本应用时将遵守任何适用的第三方条款和条件。 `,
  title6: `条款和终止 `,
  paragraph12: `在您使用本应用时，这些条款将保持完全的效力和作用。我們可在任何時間以任何理由終止與您的本條款並拒絕您存取本应用。若因任何理由終止本條款，您不得嘗試使用本应用。 `,
  title7: `知识产权 `,
  paragraph13: `除与第三方网站的任何链接有关外，My Protech拥有或有许可使用本应用的所有权利、所有权和利益，包括但不限于其中的所有版权和任何其他知识产权。这些条款不应解释为将本应用或其中所含内容的所有权或拥有权转给您。未明確授予您的本应用或內容的所有權利均由我們保留。明确禁止您在未获得My Protech书面许可的情况下，删除、修改、变更或使用我们拥有的任何注册或未注册的标记/标志，以及做出任何可能被视为不公平地利用我们的声誉和商誉或可能被视为侵犯我们拥有和/或授权给我们的任何知识产权的行为。`,
  title8: `担保`,
  paragraph14: `作为本应用的一部分向您提供的任何内容、信息或材料都是在 "原样 "的基础上为您提供的。此类内容、信息和材料不构成意见或建议，因此不应完全依赖其来协助作出或不作出决定，或协助决定行动方案。在法律允许的最大范围内，我们明确排除与本应用以及其中提供的任何内容、信息或材料有关的所有陈述、保证、义务和责任。`,
  title9: `责任`,
  point40: `该应用程序的使用是免费提供的。 `,
  point41: `我们不对任何: `,
  point42: `损失、损害、成本和费用，这些损失、损害、成本和费用不是您合理预见的，也不是您或我们在签订这些条款时能够合理预见的。; `,
  point43: `或利润、合同或业务的损失，预期结果或预期储蓄的损失。 `,
  title10: `数据保护和隐私 `,
  point44: `我们致力于保护(i)您的隐私；以及(ii)您使用本应用程序所提供的信息的保密性。根据《1988年数据保护法》，数据控制者是My Protech Sdn Bhd（1307580-A），其注册地址为 IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia。 `,
  point45: `当您通过本应用提供任何个人信息时，例如，当您注册并建立一个账户时，我们将把该信息用于以下目的: `,
  point46: `以协助向您提供应用程序中的功能和服务; `,
  point47: `通知您有关应用程序的变化; `,
  point48: `并通知您有关本应用的额外内容和功能。 `,
  point49: `我们可能会向与活动相关的第三方披露您的个人信息（即为验证目的向第三方提供信息）。 `,
  point50: `你提供给我们的所有信息都储存在我们的安全服务器中。通过提交您的个人数据，您同意这种转移和/或存储。我们将采取一切合理的必要步骤，确保您的数据得到安全处理，并符合数据保护原则。不幸的是，通过互联网传输信息并不完全安全。尽管我们将尽最大努力保护您的个人数据，但我们不能保证您传输到我们应用程序的数据的安全性；任何传输的风险都由您自己承担。一旦我们收到您的信息，我们将使用严格的程序和安全功能，试图防止未经授权的访问。 `,
  title11: `总览`,
  point51: `您可列印並保留本條款之副本，本條款構成您與我們之間的完整協議，並取代與本应用有關的任何其他通訊或廣告。`,
  point52: `这些条款只有在我们事先书面同意的情况下才可以修改。我们可以在任何时候改变或修改这些条款，并立即生效且不另行通知。在該等變更後繼續使用本应用，您將被視為已接受本條款的任何修訂。 `,
  point53: `这些条款及其执行应受马来西亚法律的管辖并按其解释，各方在此接受马来西亚法院的非专属管辖权。`,
  point54: `您应遵守适用于您在任何国家使用本应用的所有外国和当地法律和法规，包括但不限于消费者法、出口管制法律和法规。`,
  point55: `您同意，由於本应用的獨特性質，以及我們在其中的專有權利，若您證實違反本條款，將對我們造成不可挽回的傷害，而金錢上的賠償將是不足夠的。因此，您同意我们有权获得任何有管辖权的法院所确定的初步和永久禁令救济，以执行这些条款的规定。`,
  point56: `如果这些条款的任何规定被宣布为无效、非法或不可执行，这些条款的其余部分将在适用法律允许的范围内有效并可执行。在这种情况下，双方同意尽最大努力，在适用法律允许的范围内，用一个能实现无效或不可执行的条款来取代无效或不可执行的条款。`,
  point57: `本条款的任何一方在任何时候未能执行本条款下的任何条款或条件，将不被视为放弃该方此后执行本条款的每一条款和条件的权利。`,
  point58: `任何一方都不对超出该方合理控制范围的情况造成的延误负责，但不履行义务的一方应合理地努力避免或消除不履行义务的原因，并在这些原因消除后继续履行本协议。`,
  point59: `这些条款中的任何内容都不应直接或间接给予任何第三方任何可强制执行的利益或针对我们的任何行动权利，并且这些第三方无权对我们强制执行这些条款的任何条款。`,
  point60: `如果您觉得本应用上出现的任何材料具有攻击性、令人反感或潜在的诽谤性，请联系我们，提供您的投诉性质和投诉所涉及的材料的全部细节。 `,
}

export default class Tutorial extends Component {
  render() {
    return (
      <div className="min-h-screen bg-gray-100">
        <SEO
          title="Arus Oil - Terms and Conditions"
          description="This page sets out the Terms and Conditions (“Terms”) on which the My Protech Sdn Bhd (1307580-A) (“My Protech”), having its registered office at IOI Rio Office Tower, Bandar Puteri Puchong, Selangor, Malaysia (“We”, “Us” or “Our”) provides you (“You” , “Your” or “User”) with access to ‘Arus Oil’ (the “App”)"
        />
        <Header page="Terms and Conditions" />
        <header>
          <div className="pt-14 max-w-7xl flex mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-extrabold tracking-tight text-warm-gray-900">
              {termsandcondition.title1}
            </h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="block px-4 py-8 sm:px-0">
              <div className="flex flex-col mt-2">
                <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
                  <div className="bg-white px-4 py-3 flex flex-col items-center justify-between border-t border-gray-200 sm:px-6">
                    <div className="relative max-w-7xl text-justify pt-6 pb-16 sm:px-6">
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph1}
                        <br />
                        <br />
                        {termsandcondition.paragraph2}
                        <br />
                        <br />
                        {termsandcondition.paragraph3}
                        <br />
                        <br />
                        {termsandcondition.paragraph4}
                        <br />
                        <br />
                        {termsandcondition.paragraph5}
                        <br />
                        <br />
                        <br />
                        <span className="font-bold text-xl">
                          {termsandcondition.definition}
                        </span>
                        <br />
                        <br />
                        {termsandcondition.paragraph6}
                        <span className="font-bold">
                          {termsandcondition.users}
                        </span>{" "}
                        {termsandcondition.paragraph7}
                        <span className="font-bold">
                          {termsandcondition.usedcookingoil}
                        </span>
                        {". "}
                        {termsandcondition.paragraph8}
                        <span className="font-bold">
                          {termsandcondition.buyer}
                        </span>{" "}
                        {termsandcondition.paragraph9}
                        <span className="font-bold">
                          {termsandcondition.sellers}
                        </span>
                        {". "}
                        {termsandcondition.paragraph10}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title2}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point1}</li>
                        <li>{termsandcondition.point2}</li>
                        <li>{termsandcondition.point3}</li>
                      </ul>

                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title3}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph11}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.sellerresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point4}</li>
                        <li>{termsandcondition.point5}</li>
                        <li>{termsandcondition.point6}</li>
                        <li>{termsandcondition.point7}</li>
                        <li>{termsandcondition.point8}</li>
                        <li>{termsandcondition.point9}</li>
                        <li>
                          {termsandcondition.point10}
                          <ul className="ml-4 list-decimal">
                            <li>{termsandcondition.point11}</li>
                            <li>{termsandcondition.point12}</li>
                            <li>{termsandcondition.point13}</li>
                            <li>{termsandcondition.point14}</li>
                            <li>{termsandcondition.point15}</li>
                          </ul>
                        </li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.myprotechresp}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point16}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point17}</li>
                          <li>{termsandcondition.point18}</li>
                          <li>{termsandcondition.point19}</li>
                          <li>{termsandcondition.point20}</li>
                        </ul>
                        <li>{termsandcondition.point21}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point22}</li>
                          <li>{termsandcondition.point23}</li>
                          <li>{termsandcondition.point24}</li>
                          <li>{termsandcondition.point25}</li>
                          <li>{termsandcondition.point26}</li>
                          <li>{termsandcondition.point27}</li>
                        </ul>
                        <li>{termsandcondition.point28}</li>
                        <li>{termsandcondition.point29}</li>
                        <li>{termsandcondition.point30}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title4}
                      </h2>
                      <ul className="ml-4 list-disc text-sm">
                        <li>{termsandcondition.point31}</li>
                        <li>{termsandcondition.point32}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title5}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.point33}
                      </p>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point34}</li>
                        <li>{termsandcondition.point35}</li>
                        <li>{termsandcondition.point36}</li>
                        <li>{termsandcondition.point37}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point38}</li>
                          <li>{termsandcondition.point39}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title6}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph12}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title7}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph13}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title8}
                      </h2>
                      <p className="mt-2 text-sm">
                        {termsandcondition.paragraph14}
                      </p>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title9}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point40}</li>
                        <li>{termsandcondition.point41}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point42}</li>
                          <li>{termsandcondition.point43}</li>
                        </ul>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title10}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point44}</li>
                        <li>{termsandcondition.point45}</li>
                        <ul className="ml-4 list-decimal">
                          <li>{termsandcondition.point46}</li>
                          <li>{termsandcondition.point47}</li>
                          <li>{termsandcondition.point48}</li>
                        </ul>
                        <li>{termsandcondition.point49}</li>
                        <li>{termsandcondition.point50}</li>
                      </ul>
                      <h2 className="mt-12 text-xl font-bold">
                        {termsandcondition.title11}
                      </h2>
                      <ul className="ml-4 mt-2 list-disc text-sm">
                        <li>{termsandcondition.point51}</li>
                        <li>{termsandcondition.point52}</li>
                        <li>{termsandcondition.point53}</li>
                        <li>{termsandcondition.point54}</li>
                        <li>{termsandcondition.point55}</li>
                        <li>{termsandcondition.point56}</li>
                        <li>{termsandcondition.point57}</li>
                        <li>{termsandcondition.point58}</li>
                        <li>{termsandcondition.point59}</li>
                        <li>{termsandcondition.point60}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    )
  }
}
